import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {AuthService, EchoService, SettingsService} from "@customcoding/shared";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [NgbModalConfig, NgbModal]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    lang = new Subject<string>();
    title = 'dashboard-app';
    demo = false;

    constructor(
        private translate: TranslateService,
        private pushService: EchoService,
        private settings: SettingsService,
        private ngbModalConfig: NgbModalConfig,
        private authService: AuthService) {
        this.setUpNGBConfigs();
    }


    ngOnInit(): void {

        this.demo = this.settings.get('BETA');
        //this.demo = environment?.beta; alternative
    }

    ngAfterViewInit() {
        //console.log('current user', this.authService.getCurrentUser());
    }


    ngOnDestroy() {
        this.pushService.leavePushChannel();
    }

    private setUpNGBConfigs() {
        this.ngbModalConfig.scrollable = true;
    }

}
