import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Config} from '@customcoding/shared';

@Injectable()
export class AppConfigService extends Config {
	public getAppEnvironmentProperties(): any {
		return environment;
	}

}